import { baseEnvironmentProd } from 'src/environments/baseEnvironment.prod';

export const environment = {
  ...baseEnvironmentProd,
  organizationPrefix: 'LMB',
  organizationUUID: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  activeLanguage: 'it-IT',
  tableStructureUUID: '141ffb4a-620c-493c-a5a2-3ae7783ba3a0',
  multipleAdminCommunity: false,
  privacyUrl: 'https://addiction.it/privacy-policy/',
  conditionTermUrl: 'https://addiction.it/privacy-policy/',
  requiredField:{
  },
  systemToken: '',
  htmlFields: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ header: 1 }, { header: 2 }],
    ['blockquote'],
    [{ color: [] }, { background: [] }],
    [{ script: 'sub'}, { script: 'super' }, 'link'],
    [{ list: 'ordered'}, { list: 'bullet' }, { align: [] }],
    ['clean']
  ],

  manageTemplates: false,
  manageUserFilter: false,

  basicConfiguration: undefined,
  googleAPIKey: '',
  productEditorTabIcons: [],
  pwaThemeColor: 'rgba(0, 72, 205, 1.0)'
};
